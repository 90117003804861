<template>
  <!--    Marker Form-->
  <validation-observer
    ref="markerForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateMarkerMode?'Update':'Create'} Marker`"
      :show="markerModalOpened"
      :show-overlay="deletingMarker"
      size="md"
      @onClose="closeMarkerModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedMarkerId"
            :options="markers"
            label-name="title"
            value-name="id"
            placeholder="Markers"
            @input="populateMarkersForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteMarker()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetMarkerForm()"
          />
        </div>
      </b-row>
      <lenon-input
        v-model="marker.title"
        name="title"
        placeholder="Enter title"
        rules="required"
        label="Title"
      />
      <lenon-select
        v-model="marker.class_id"
        :options="classes"
        label-name="name"
        value-name="id"
        placeholder="Select Class"
        label="Class"
        rules="required"
      />
      <lenon-select
        v-model="marker.subject_id"
        :options="subjects"
        label-name="name"
        value-name="id"
        placeholder="Select Subject"
        label="Subject"
        rules="required"
      />
      <lenon-select
        v-model="answerType"
        :options="answerTypes"
        label-name="name"
        value-name="id"
        placeholder="Select Answer Type"
        label="Answer Type"
        rules="required"
      />
      <div
        v-if="answerType==='assessment'"
        class="row"
      >
        <div class="col-12">
          <lenon-select
            v-model="selectedAssessment"
            :options="assessments"
            label-name="title"
            value-name="id"
            placeholder="Select Assessment"
            label="Assessment"
            rules="required"
          />
        </div>
      </div>
      <lenon-select
        v-model="marker.assessment_id"
        :options="conductedAssessments"
        label-name="title"
        value-name="id"
        placeholder="Conducted Assessments"
        label="Assigned Assessment"
        rules="required"
      />
      <lenon-select
        v-model="marker.page_type"
        :options="pageTypes"
        label-name="name"
        value-name="id"
        placeholder="Select Page Type"
        label="Page Type"
        rules="required"
      />
      <div
        v-if="answerType==='custom'"
        class="row"
      >
        <div class="col-12">
          <lenon-file-button
            label="Upload Answers"
            variant="outline-primary"
            class="mr-1"
            tool-tip-text="If you have already recorded the answers in a document, simply take a clear picture and upload it."
            accept=".pgn,.jpg,.jpeg"
            @onFileUpload="uploadFile"
          />
        </div>
      </div>
      <p
        v-if="answerType==='custom'"
        class="text-center mt-1"
      >
        <b>OR Fill Them Manually <span class="text-success">(TOTAL: {{ +marker.total_questions }})</span></b>
      </p>
      <p
        v-if="answerType==='assessment'"
        class="text-center mt-1"
      >
        <b>Answers to Assessment <span class="text-success">(TOTAL: {{ +marker.total_questions }})</span></b>
      </p>
      <div
        v-for="(item,x) in marker.answers"
        :key="x"
        class="row align-items-center"
      >
        <div
          class="col-md-10"
        >
          <lenon-input
            v-model="item.answer"
            name="total_questions"
            :placeholder="`Answer to Question ${x+1} (A,B,C,D,E)`"
            rules="required|valid_answer"
            :label="`Answer to Question ${x+1} (A,B,C,D,E)`"
            :readonly="answerType==='assessment'"
          />
        </div>
        <div
          v-if="answerType==='custom'"
          class="col-md-2"
        >
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mt-1"
            icon-only
            @onClick="removeAnswer(x)"
          />
        </div>
      </div>
      <div
        v-if="answerType==='custom'"
        class="row"
      >
        <div class="col-12">
          <lenon-button
            class="float-right"
            variant="outline-primary"
            label="Add Answer"
            icon="PlusIcon"
            @onClick="addAnswer()"
          />
        </div>
      </div>
      <div class="mb-1" />
      <small class="text-danger">Please note: Total questions must not exceed {{ questionsLimit }}. This restriction may change in the future.</small>
      <div class="mb-1" />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeMarkerModal()"
          />
          <lenon-button
            :label="updateMarkerMode?'Update':'Create'"
            :disabled="invalid || invalidAnswers"
            :loading="markerLoading"
            loading-text="Loading..."
            @onClick="updateMarkerMode?updateMarker():createMarker()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_EXPENSE_M,
  DELETE_EXPENSE_M,
  UPDATE_EXPENSE_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonFileButton from '@/lenon/components/LenonFileButton'

export default {
  name: 'MarkerSetup',
  components: {
    LenonFileButton,
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingMarker: false,
      markerModalOpened: false,
      updateMarkerMode: false,
      markerLoading: false,
      selectedMarkerId: null,
      answerTypes: [{ id: 'assessment', name: 'From Generated Assessment' }, { id: 'custom', name: 'Custom Answers' }],
      pageTypes: [{ id: 'a4', name: 'A4 Sheet' }, { id: 'a5', name: 'A5 Sheet' }],
      answerType: null,
      selectedAssessment: null,
      assessments: [],
      marker: {
        id: null,
        title: null,
        class_id: null,
        subject_id: null,
        total_questions: null,
        page_type: null,
        assessment_id: null,
        answers: [],
      },
      conductedAssessments: [],
    }
  },
  computed: {
    total() {
      return this.marker.total_questions
    },
    questionsLimit() {
      return this.marker.page_type === 'a4' ? 66 : 46
    },
    invalidAnswers() {
      return (this.marker.answers.length === 0 || this.marker.answers.length > this.questionsLimit)
    },
    ans() {
      return this.marker.answers
    },
    markers() {
      return this.$store.getters['markers/markers']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    classes() {
      return this.$store.getters['termsClasses/allClasses']
    },
    selectedMarker() {
      return this
        .markers.find(g => +g.id === +this.selectedMarkerId)
    },
    classSubjectAnswerType() {
      return +(this.marker.subject_id) * (+this.marker.class_id) * (+this.answerType)
    },
    classSubject() {
      return +(this.marker.subject_id) * (+this.marker.class_id)
    },
  },
  watch: {
    selectedAssessment(val) {
      if (val) {
        this.getAnswersFromAssessment()
      }
    },
    classSubjectAnswerType(val) {
      if (this.answerType === 'assessment') {
        this.getAssessments()
      }
    },
    classSubject(val) {
      if (val) {
        this.getConductedAssessments()
      }
    },
    ans(val) {
      if (val) {
        this.marker.total_questions = val.length
      }
    },
    modalOpened(opened) {
      this.markerModalOpened = opened
    },
    selectedMarkerId(id) {
      if (!id) {
        this.resetMarkerForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedMarkerId = id
      }
    },
  },
  methods: {
    getAssessments() {
      this.$http.get(`markers/assessments-for-smart-marker?class_id=${this.marker.class_id}&subject_id=${this.marker.subject_id}`).then(res => {
        this.assessments = res.data
      })
    },
    getAnswersFromAssessment() {
      this.markerLoading = true
      this.$http.get(`markers/answers-from-assessment/${this.selectedAssessment}`).then(res => {
        this.marker.answers = res.data
      }).catch(err => {
        this.showError('Failed to load answers')
      }).finally(() => {
        this.markerLoading = false
      })
    },
    createFields(val) {
      this.marker.answers = []
      if (this.marker.total_questions) {
        for (let i = 0; i < this.marker.total_questions; i++) {
          this.marker.answers.push({ answer: null })
        }
      }
    },
    uploadFile(e) {
      this.confirmAction('Do you want to upload this file?').then(res => {
        if (res) {
          this.deletingMarker = true
          this.$http.post('markers/upload-answers', { answer: e.dataUrl, total: this.marker.total_questions }).then(res => {
            const { data } = res
            let error = false
            data.forEach(a => {
              if (!a.answer) {
                error = true
              }
            })
            if (error) {
              this.showError('It looks like the answer sheet you uploaded is invalid')
            } else {
              this.marker.answers = data
            }
          }).catch(err => {
            this.showError('Failed to read answers from image')
          }).finally(() => {
            this.deletingMarker = false
          })
        }
      })
    },
    removeAnswer(index) {
      this.marker.answers.splice(index, 1)
    },
    addAnswer() {
      this.marker.answers.push({ answer: null })
    },
    resetMarkerForm() {
      this.updateMarkerMode = false
      this.marker = {
        id: null,
        title: null,
        class_id: null,
        subject_id: null,
        assessment_id: null,
        total_questions: null,
        answer_type: null,
        page_type: null,
        answers: [],
      }
      this.selectedMarkerId = null
      this.$refs.markerForm.reset()
    },
    populateMarkersForm(ef) {
      if (this.selectedMarker) {
        this.updateMarkerMode = true
        const qt = this.selectedMarker.assessment_id ? 'assessment' : 'custom'
        this.marker = {
          ...this.selectedMarker,
          answer_type: qt,
        }
      } else {
        this.resetMarkerForm()
      }
    },
    closeMarkerModal() {
      this.markerModalOpened = false
      this.$emit('modalClosed')
    },
    updateMarker() {
      this.error = {}
      if (!this.marker.id) {
        return
      }
      if (+this.marker.total_questions > 60) {
        this.showInfo('Please make sure total questions is 60 or less')
      } else {
        this.markerLoading = true
        this.$http.put(`markers/${this.marker.id}`, this.marker)
          .then(res => {
            this.markerLoading = false
            this.showSuccess('Updated marker successfully')
            this.$store.commit('markers/updateMarker', res.data)
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to update marker')
            this.markerLoading = false
          })
      }
    },
    createMarker() {
      this.error = {}
      if (+this.marker.total_questions > 60) {
        this.showInfo('Please make sure total questions is 60 or less')
      } else {
        this.markerLoading = true
        this.$http.post('markers', this.marker).then(res => {
          this.showSuccess('Created marker successfully')
          this.markerLoading = false
          this.$store.commit('markers/addMarker', res.data)
          this.resetMarkerForm()
        })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to create marker')
            this.markerLoading = false
          })
      }
    },
    deleteMarker() {
      if (!this.selectedMarkerId) {
        this.showInfo('Please select a marker')
        return
      }
      this.deletingMarker = true
      this.$http.delete(`markers/${this.selectedMarkerId}`).then(() => {
        this.deletingMarker = false
        this.showSuccess('Deleted marker successfully')
        this.$store.commit('markers/removeMarker', this.selectedMarkerId)
        this.selectedMarkerId = null
        this.resetMarkerForm()
      })
        .catch(err => {
          logData(err)
          this.showError('Failed to delete marker')
          this.deletingMarker = false
        })
    },
    getConductedAssessments() {
      this.$http.get(`markers/current-term-assessments?class_id=${this.marker.class_id}&subject_id=${this.marker.subject_id}`).then(res => {
        this.conductedAssessments = res.data
      })
    },
  },
}
</script>
